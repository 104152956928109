import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import {Link} from 'gatsby'

const Privacy: React.FC = () => {
  const domain = 'https://kindawow.com'
  const domainFriendly = 'kindawow.com'
  const company = 'KINDAWOW'
  const email = 'legal@kindawow.com'
  const ads = false
  const social = false
  const blog = false
  const oauth = false

  return (
    <Layout>
      <Head title="Privacy" />
      <div style={{marginTop: '3em', fontSize: '0.9em'}}>
        <div className="container ml-20 py-2 w-1/2" style={{marginTop: '3em', fontSize: '0.9em'}}>
          <h4>Privacy Policy</h4>
          <p>
            We take your privacy seriously. Please read the following to learn more about our privacy policy. The
            federal government and technology industry have developed practical tips to help you guard against Internet
            fraud, secure your computer and protect your personal information. This privacy policy applies to {domain}{' '}
            (“{domainFriendly}”) owned and operated by {company}. This privacy policy describes how {company} collects
            and uses the personal information you provide on our website: {domainFriendly}. It also describes the
            choices available to you regarding our use of your personal information and how you can access and update
            this information. If you have questions or complaints regarding our privacy policy or practices, please
            contact us at <a href="mailto:{email}">{email}</a>.
          </p>
          <h4>Information and collection of use</h4>
          <p>
            We collect personal information when you register with {domainFriendly}.{domainFriendly} may combine
            information about you that we have with information we obtain from business partners or other companies such
            as Google, for the purpose of delivering personalized content. When you register we ask for information such
            as your name, phone number, and email address.
            {domainFriendly} uses the information for the following general purposes: to customize the content you see,
            fulfill your requests for services, improve our services, contact you, and conduct research.
          </p>
          <h4>Information Sharing</h4>
          <p>
            We do not rent, sell, or share personal information about you with other people or non-affiliated companies
            except to provide products or services you‘ve requested, when we have your permission, or when we respond to
            subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against
            legal claims. If we become involved in a merger, acquisition, or any form of sale of some or all of its
            assets, we will provide notice before personal information is transferred and becomes subject to a different
            privacy policy.
          </p>
          <h4>Cookies</h4>
          <p>
            We use “cookies” on {domain} to track how users use our website. A cookie is a small text file that is
            stored on a user’s computer for record-keeping purposes. We link some information we store in cookies to
            personal information you submit in order to deliver a better experience on the service. We use both session
            ID cookies and persistent cookies. A session ID cookie expires when you close your browser. We use session
            cookies to make it easier for you to navigate our site. A persistent cookie remains on your hard drive for
            an extended period of time. We set a persistent cookie to remember your login, so you don’t have to sign in
            more than once. You can remove persistent cookies by following directions provided in your Internet
            browser’s “help” file. If you reject cookies, you may still use our website, but your ability to use some
            areas of our site, will be limited. As is true of most websites, we gather certain information automatically
            and store it in log files. These are non-personal information which includes internet protocol (IP)
            addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time
            stamp, and clickstream data. We use this information, which does not identify individual users, to analyze
            trends, to administer the website, to track users’ movements around the website and to gather demographic
            information about our user base as a whole. We do link this automatically-collected data to personal
            information.
          </p>
          <p>
            In some cases, such as when you login or access secure areas of
            {domainFriendly} your internet protocol (IP) addresses information is collected and attached to your
            account. You can opt out of this behavior by configuring your Internet Browser to send a “Do Not Track”
            request.
          </p>
          {ads && (
            <>
              <h4>Targeting / Re-targeting</h4>
              <p>
                We partner with a third party ad network to either display advertising on our website or to manage our
                advertising on other sites. Our ad network partner uses cookies and Web beacons to collect non-personal
                information about your activities on this and other websites to provide you targeted advertising based
                upon your interests. If you wish to not have this information used for the purpose of serving you
                targeted ads, you may opt-out. Please note this does not opt you out of being served advertising. You
                will continue to receive generic ads.
              </p>
            </>
          )}
          <h4>Content of items</h4>
          <p>
            The contents of any item that you post directly to the site, including any text, images and audio, are
            stored and maintained on our servers in order to publish these items and provide the Service. Your submitted
            content will be associated with your account.
          </p>
          <h4>Confidentiality and Security</h4>
          <p>
            The security of your personal information is important to us. We follow generally accepted standards to
            protect the personal information submitted to us, both during transmission and once we receive it. We limit
            access to personal information about you to employees who we believe reasonably need to come into contact
            with that information to provide products or services to you or in order to do their jobs. These individuals
            are bound by confidentiality obligations and may be subject to discipline, including termination and
            criminal prosecution, if they fail to meet these obligations. No method of transmission over the Internet,
            or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute
            security.
          </p>
          <h4>Third Party Sites</h4>
          <p>
            This Policy only applies to the {domainFriendly} website and services. We do not exercise control over the
            sites that our service links to. These other sites may place their own cookies or other files on your
            computer, collect data or solicit personal information from you. We encourage you to read such privacy
            policies of any third-party sites you visit. It is the sole responsibility of such third parties to adhere
            to any applicable restrictions on the disclosure of your personal information, and we shall not be liable
            for wrongful use or disclosure of your personal information by any third party.
            {ads && (
              <>
                (i) {domainFriendly} may sometimes use third-party advertising companies to serve ads to users; (ii)
                Third-party advertising companies may use non-personally identifiable information about user visits in
                order to provide advertisements for goods and services that may be of interest to the user. (iii) You
                can find more information about these practices and the your choices concerning these practices at
                http://networkadvertising.org/managing/opt_out.asp.
              </>
            )}
          </p>
          <h4>Data Integrity</h4>
          <p>
            We process personal information only for the purposes for which it was collected and in accordance with this
            policy. We review our data collection, storage and processing practices to ensure that we only collect,
            store and process the personal information needed to provide or improve our services. We take reasonable
            steps to ensure that the personal information we process is accurate, complete, and current, but we depend
            on our users to update or correct their personal information whenever necessary. Users can update, correct
            or delete their personal information through the profile page of their account or by emailing us at{' '}
            <a href="mailto:{email}">{email}</a>. We will retain your information for as long as your account is active
            or as needed to provide you services. We will retain and use your information as necessary to comply with
            our legal obligations, resolve disputes, and enforce our agreements. We may send out promotional or
            marketing emails to you. Out of respect for your privacy, you may choose to stop receiving these emails by
            following the unsubscribe instructions included in these emails, accessing the email preferences in your
            account settings page or you can contact us at <a href="mailto:{email}">{email}</a>.
          </p>
          {blog && (
            <>
              <h4>Blog / Forums</h4>
              <p>
                Our website offers publicly accessible blogs or community forums. You should be aware that any
                information you provide in these areas may be read, collected, and used by others who access them. To
                request removal of your personal information from our blog or community forum, contact us at
                <a href="mailto:{email}">{email}</a> In some cases, we may not be able to remove your personal
                information, in which case we will let you know if we are unable to do so and why.
              </p>
            </>
          )}
          {social && (
            <>
              <h4>Social Media Widgets</h4>
              <p>
                Our website include Social Media Features, such as the Facebook and Twitter button and Widgets, such as
                the Share this button or interactive mini-programs that run on our website. These Features may collect
                your IP address, which page you are visiting on our website, and may set a cookie to enable the Feature
                to function properly. Social Media Features and Widgets are either hosted by a third party or hosted
                directly on our Site. Your interactions with these Features are governed by the privacy policy of the
                company providing it.
              </p>
            </>
          )}
          {oauth && (
            <>
              <h4>Single Sign-on</h4>
              <p>
                You can log in to our website using sign-in services such as Facebook Connect or an Open ID provider.
                These services will authenticate your identity and provide you the option to share certain personal
                information with us such as your name and email address to pre-populate our sign up form. Services like
                Facebook Connect give you the option to post information about your activities on this Web site to your
                profile page to share with others within your network.
              </p>
            </>
          )}
          <h4>Changes to this Policy</h4>
          <p>
            We may update this policy. We will notify you about significant changes in the way we treat personal
            information by sending a notice to the primary email address specified in your {domainFriendly} account or
            by placing a prominent notice on our site. If we make any material changes we will notify you by email (sent
            to the e-mail address specified in your account) or by means of a notice on this Site prior to the change
            becoming effective. We encourage you to periodically review this page for the latest information on our
            privacy practices.
          </p>
          {ads && (
            <>
              <h4>The DoubleClick cookie</h4>
              <p>
                The DoubleClick cookie is used by Google in the ads served on the websites of its partners, such as
                websites displaying AdSense ads or participating in Google certified ad networks. When users visit a
                partner‘s website and either view or click on an ad, a cookie may be dropped on that end user‘s browser.
              </p>
              <p>
                Third party vendors, including Google, use cookies to serve ads based on your prior visits to our
                website. Google‘s use of the DoubleClick cookie enables it and its partners to serve ads based on your
                visit to our sites and/or other sites on the Internet. You may opt out of the use of the DoubleClick
                cookie for interest-based advertising by visiting Ads Settings. (Alternatively, you can opt out of a
                third-party vendor‘s use of cookies for interest based advertising by visiting{' '}
                <a href="http://aboutads.info">aboutads.info</a>.)
              </p>
            </>
          )}
          <h4>Contact Information</h4>
          <p>
            If you have any questions or comments about these Privacy Policy as outlined above, you can contact us at:{' '}
            <a href="mailto:{email}">{email}</a>
          </p>
        </div>
      </div>

      <br />
      <br />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default Privacy
